import { render, staticRenderFns } from "./VinculationHistoryComponent.vue?vue&type=template&id=6688ebc2&scoped=true"
import script from "./VinculationHistoryComponent.js?vue&type=script&lang=js&external"
export * from "./VinculationHistoryComponent.js?vue&type=script&lang=js&external"
import style0 from "./VinculationHistoryComponent.vue?vue&type=style&index=0&id=6688ebc2&prod&scoped=true&lang=scss"
import style1 from "./VinculationHistoryComponent.vue?vue&type=style&index=1&id=6688ebc2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6688ebc2",
  null
  
)

export default component.exports