import { filterRulesMapped } from '@/tools/filterRules'
import vinculationApi from '@/api/vinculation.api'
import { mapMutations, mapActions } from 'vuex'
import { SnackbarStyle } from '@/constants/constants'
import i18n from '@/i18n'
import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue'
import BreadcrumbComponent from '@/components/commons/breadcrumb/BreadcrumbComponent.vue'
import selectorService from '@/business/selectorService'
import { TimeRanges, CustomTimeRangeTypes } from '@colven/common-domain-lib/lib'
import {  
  ISODateFromUnixTimestamp, 
  selectorDateTimeValidation, 
  dateSortFunction,
  getChildrenFromList,
  getDateTimeRange
} from '@/tools/functions'
import Vue from 'vue'
import thingApi from '@/api/thing.api'
import activityApi from '@/api/activity.api'


export default {
  name: 'VinculationHistoryComponent',
  components: {
    SelectorComponent,
    BreadcrumbComponent,
  },
  data: () => ({
    showSelector: true,
    tableHeaders: [],
    tableFilterRules: {},
    tableData: [],
    tableDataKey: '_id',
    tableComponentButtons: [],
    queryPageSize: 100,
    queryPageNumber: 1,
    pageTotal: undefined,
    loadingTable: false,
    selectorModel: selectorService.getSelectorModel(true),
    workFrontFilter: {
      id: 'workFront',
      name: i18n.t('headers.workFront'),
      show: false,
      disabled: true,
      showDialog: false,
      singleSelect: false,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    tabs: [
      {
        id: 'tab-summary',
        name: i18n.t('poi.filters')
      }
    ],
    selectedTab: 'tab-summary',
    breadcrumbButtonsHarvestedSummaryReport: [],
    timeFormat: null,
    filterSwitch: true,
    dateAndTimeRange: TimeRanges.LAST_HOUR,
    dateAndTimeRangeCustomType: CustomTimeRangeTypes.DATE,
    sinceDate: null,
    sinceTime: null,
    toDate: null,
    toTime: null,
    autoReport: false,
    things: [],
    idThings: [],
    timeStampFrom: null,
    timeStampTo: null,

    
  }),
  computed: {
  },
  created () {
    // Agrego el botón del breadcrumb para mostrar/ocultar el selector
    this.breadcrumbButtonsHarvestedSummaryReport.push(
      {
        id: 'setting-btn',
        icon: 'settings',
        show: () => { return true },
        disable: this.disableSelectorButton.bind(this),
        function: this.selector.bind(this)
      })
    // Si hay query params, los setea para incluirlos en la llamada al backend, de lo contrario, busca todas las reglas de vinculación
    if (this.$route.query) {
      this.queryPageSize = this.$route.query.pageSize ? Number(this.$route.query.pageSize) : 100
      this.queryPageNumber = this.$route.query.pageNumber ? Number(this.$route.query.pageNumber) : 1
    }
    // Valida que los qery params sean correctos y completos
    if ((this.queryPageSize && this.queryPageSize <= 0) || (this.queryPageNumber && this.queryPageNumber <= 0) || (this.queryPageNumber && !this.queryPageSize) || (!this.queryPageNumber && this.queryPageSize)) {
      this.showSnackbar({ visible: true, text: this.$t('error.invalidQueryParams'), timeout: 6000, style: SnackbarStyle.ERROR })
      this.queryPageSize = 100
      this.queryPageNumber = undefined
    }
  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    this.selectorModel.hideAutoReport = true;
    this.selectorModel.switches = [];
    this.selectorModel.switches.push(
      {
        id:1, 
        show:true, 
        name:i18n.t('table.filterBy') +" "+ i18n.t('table.date'),
        value: true, 
        textTrue:i18n.t('yes'),
        textFalse: i18n.t('no')
      })

    // Obtener el historial de vinculaciones
    //this.getData()
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    ...mapMutations('dialog', {
      openDialog: 'openDialog',
      closeDialog: 'closeDialog'
    }),
    /**
     * Para deshabilitar el botón del breadcrumb
     */
    disableSelectorButton () {
      return this.disableSelector
    },
     /**
     * Evento lanzado por el selector cuando se cierra con la X
     */
    close () {
      this.showSelector = false
    },
     /**
     * Para el botón del breadcrumb
     */
    selector () {
      this.showSelector = !this.showSelector
    },
    /**
     * Incializador del selector
     */
    async initializeSelector () {
      this.selectorModel.filters.push(
        this.workFrontFilter,
      )
      // callback cuando se seleccionan items en los filtros

      // datos para el selector de equipos
      const activitiesResponse = await activityApi.getAllForSelector(this.model.sector);
      const thingTypesResponse = await thingApi.getThingTypesByActivity(
        activitiesResponse.selectedData.map(a => a.id), this.model.sector
      );
      this.workFrontFilter.selectedData = [];
      this.workFrontFilter.disabled = false;
      const workFrontResponse = await thingApi.getWorkFrontsByTypes(
        thingTypesResponse.selectedData.map(t => t.id), this.model.sector
      );
      this.workFrontFilter.data.cleanAndUpdate(workFrontResponse.data);
    },

    validateSelector () {
      const that = this
      Vue.nextTick(function () {
        const customTimeValidation = selectorDateTimeValidation(that.selectorModel.selectedDateAndTimeRange,
          that.selectorModel.selectedDateAndTimeRangeCustomType, that.selectorModel.customDateTimeValidForm,
          that.selectorModel.sinceDate, that.selectorModel.sinceTime, that.selectorModel.toDate, that.selectorModel.toTime)

        const timeFormatSelected = that.selectorModel.selectedTimeFormat

        that.selectorModel.disableGetReportButton = customTimeValidation || !timeFormatSelected
      })
    },
    showDateFilter(){
      const that = this
      Vue.nextTick(function () {
        const showDateFilterSwitch = that.selectorModel.switches.filter(ms=>ms.id == 1)
        if(showDateFilterSwitch.length > 0){
          that.selectorModel.showDateAndTimeSelector = showDateFilterSwitch[0].value
        }
      })
    },

    tabSelected (id) {
      const detailRef = this.$refs['detail']
      if (id === 'tab-summary' && detailRef) {
        this.commitVisible({ visible: false })
        detailRef.loadingSpinner = false
        this.loadingSpinner = true
        this.$refs['vinculationHistoryTableComponent'].change()
      } 
      this.selectedTab = id
    },
    updateFilterModelDate (filterData) {
      const filterDataTimeRange = filterData.dateTimeRange || null
      const filterDataTimeType = filterData.customDateTimeRangeType || null

      if (filterDataTimeRange == null && filterDataTimeType == null) {
        if (filterData.from || filterData.to) {
          this.selectorModel.selectedDateAndTimeRange = TimeRanges.CUSTOM
          this.selectorModel.selectedDateAndTimeRangeCustomType = CustomTimeRangeTypes.DATE_AND_TIME
        }
      } else {
        this.selectorModel.selectedDateAndTimeRange = filterDataTimeRange || TimeRanges.LAST_HOUR
        this.selectorModel.selectedDateAndTimeRangeCustomType = filterDataTimeType || CustomTimeRangeTypes.DATE
      }

      if (this.selectorModel.selectedDateAndTimeRange === TimeRanges.CUSTOM) {
        const sinceDateData = ISODateFromUnixTimestamp(filterData.from, true)
        const toDateData = ISODateFromUnixTimestamp(filterData.to, true)

        // Carga las fechas en formato string desde el timestamp
        this.selectorModel.sinceDate = sinceDateData.date
        this.selectorModel.toDate = toDateData.date

        if (this.selectorModel.selectedDateAndTimeRangeCustomType === CustomTimeRangeTypes.DATE_AND_TIME) {
          this.selectorModel.sinceTime = sinceDateData.time
          this.selectorModel.toTime = toDateData.time
        }
      }
    },
    /**
     * Resize de los split panel
     */
    resize () {
    },
    /**
     * Click en una fila
     * @param {*} data
     */
    clickRow () {
    },
    /**
     * Refrescar la data
     */
    refreshTable () {
      this.getReport()
    },
    /**
     * Se ejecuta cuando cambian los datos de la tabla (por búsqueda, filtrado, agregar/quitar columnas, etc.)
     * @param {*} newData
     */
    dataChangeEventHandler () {
    },

    getData () {
      this.loadingTable = true
      this.showSelector = false
      this.disableSelector = false;
      vinculationApi.getVinculationHistoryTotalCount().then(response => {
        this.pageTotal = Number(response.data.count)
      })
      vinculationApi.getvinculationHistoryReport(this.queryPageSize, this.queryPageNumber).then(response => {
        this.tableData.cleanAndUpdate(response.data.data)
        this.tableHeaders.cleanAndUpdate(response.data.headers)
        this.setSortFunctions()
        this.setTableFilterRules()
        this.loadingTable = false
      })

    },

    setTableFilterRules () {
      this.tableFilterRules = {}
      this.tableHeaders.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    notImplemented () {
      this.showSnackbar({ visible: true, text: this.$t('notImplemented'), timeout: 6000, style: SnackbarStyle.WARNING })
    },
    externalPaginator (pageSize, pageNumber) {
      this.queryPageNumber = Number(pageNumber)
      this.queryPageSize = Number(pageSize)
    },
    setSortFunctions () {
      /*
      Para ordenar las columnas fecha y hora
      TO DO: analizar la posibilidad de incluir este tipo de sorting en el componente genérico
      */
      this.tableHeaders.find(header => header.value === 'trackTimestamp').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY HH:mm:ss') }
    },

     async getReport() {
      // guardo los datos elegidos en los selectores
      this.filterSwitch = this.selectorModel.filterSwitch.value;
      this.things = [];
      this.idThings = [];
      if (this.selectorModel.filterSwitch.value) {
        getChildrenFromList(this.selectorModel.groupEquipmentFilter.selectedData, this.things);
        this.idThings =  this.things.map(thing => thing.id)
      } 

      this.dateAndTimeRange = this.selectorModel.selectedDateAndTimeRange;
      this.dateAndTimeRangeCustomType = this.selectorModel.selectedDateAndTimeRangeCustomType;
      this.sinceDate = this.selectorModel.sinceDate;
      this.sinceTime = this.selectorModel.sinceTime;
      this.toDate = this.selectorModel.toDate;
      this.toTime = this.selectorModel.toTime;
      this.autoReport = this.selectorModel.autoReport;
      this.autoReportMinutes = this.selectorModel.autoReportMinutes;
      this.autoReportName = this.selectorModel.autoReportName;

      const conversion = getDateTimeRange(this.dateAndTimeRange, this.dateAndTimeRangeCustomType,
      this.sinceDate, this.sinceTime, this.toDate, this.toTime);
      this.timeStampFrom = conversion.tFrom;
      this.timeStampTo = conversion.tTo;
      this.timeFormat = this.selectorModel.selectedTimeFormat;

      this.loadingTable = true;
      if(!this.selectorModel.showDateAndTimeSelector){
        this.timeStampFrom = null;
        this.timeStampTo = null;
      }
      if(this.idThings.length > 0 || this.selectorModel.showDateAndTimeSelector)
      {
        vinculationApi.getvinculationHistoryReportFilteredCount(this.idThings, this.timeStampFrom, this.timeStampTo).then(response => {
          this.pageTotal = Number(response.data)
        })
        vinculationApi.getvinculationHistoryReportFiltered(this.queryPageSize, this.queryPageNumber, this.idThings, this.timeStampFrom, this.timeStampTo).then(response => {
          this.tableData.cleanAndUpdate(response.data.data);
          this.tableHeaders.cleanAndUpdate(response.data.headers)
          this.setSortFunctions()
          this.setTableFilterRules()
          this.loadingTable = false;
        })
      }
      else{
        this.getData();
      }
      this.showSelector = false;
    },
  },
  watch: {
    selectorModel: {
      handler: function () {
        this.validateSelector()
        this.showDateFilter()
      },
      deep: true
    },
  }
}
