import service from '@/middleware'

const vinculationApi = {
  async getPaginated (pageSize, pageNumber) {
    let result
    if (pageSize && pageSize > 0 && pageNumber && pageNumber > 0) {
      result = await service.http.get(`/api/vinculation-validation?pageSize=${pageSize}&pageNumber=${pageNumber}`)
    } else {
      result = await service.http.get(`/api/vinculation-validation`)
    }
    return result
  },
  async getById (id) {
    const result = await service.http.get(`/api/vinculation-validation/${id}`)
    return result
  },
  async create (rules) {
    const result = await service.http.post('/api/vinculation-validation', rules)
    return result
  },
  async update (vinculationDto) {
    const result = await service.http.put('/api/vinculation-validation', vinculationDto)
    return result
  },
  async delete (id) {
    const result = await service.http.delete(`/api/vinculation-validation/${id}`)
    return result
  },
  async multipleDelete (ids) {
    const result = await service.http.put(`/api/vinculation-validation/multiple-delete`, ids)
    return result
  },
  async getTotalCount () {
    const result = await service.http.get(`/api/vinculation-validation/total-count`)
    return result
  },
  async getvinculationHistoryReport (pageSize, pageNumber) {
    let result
    if (pageSize && pageNumber) {
      result = await service.http.get(`/api/vinculation-history/report?pageSize=${pageSize}&pageNumber=${pageNumber}`)
    } else {
      result = await service.http.get(`/api/vinculation-history/report`)
    }
    return result
  },
  async getVinculationHistoryTotalCount () {
    const result = await service.http.get(`/api/vinculation-history/total`)
    return result
  },
  /**
   * Para validar el nombre de una vinculación
   * @param {*} name
   */
  async validateName (name) {
    const result = await service.http.get(`/api/vinculation-validation/validate-name/${name}`)
    return result.data
  },
  async getvinculationHistoryReportFiltered (pageSize, pageNumber, thingList, tsFrom, tsTo) {
    const body = {
      pageSize,
      pageNumber,
      thingList, 
      tsFrom,
      tsTo
    }

    const result = await service.http.post('/api/vinculation-history/report-filtered', body)
    return result
  },
  async getvinculationHistoryReportFilteredCount (thingList, tsFrom, tsTo) {
    const body = {
      thingList, 
      tsFrom,
      tsTo
    }

    const result = await service.http.post('/api/vinculation-history/report-filtered-count', body)
    return result
  },
  
}

export default vinculationApi
